import { useRouter } from 'next/router';
import { BiLeftArrowAlt } from 'react-icons/bi';

const Back = () => {
  const router = useRouter();

  const handleOnClickBack = () => {
    router.back();
  };

  return (
    <button type='button' onClick={handleOnClickBack}>
      <BiLeftArrowAlt className='min-h-[28px] min-w-[28px]' />
    </button>
  );
};

export default Back;
