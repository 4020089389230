import { Disclosure } from '@headlessui/react';
import { SOCIAL_DOMAIN } from 'globalConstants';
import { useModal } from 'hooks';
import { isEmpty, isUndefined, map } from 'lodash';
import Link from 'next/link';
import { Fragment } from 'react';
import { CgClose } from 'react-icons/cg';
import { GiHamburgerMenu } from 'react-icons/gi';
import { HiChevronDown } from 'react-icons/hi';
import ModalWrapper from '../../../../shared/modal-wrapper';

interface NavigationType {
  name?: string;
  pathname?: string;
  extraProps?: {};
  children?: NavigationType[];
}

const Menu = () => {
  const { isShowing: isShowingMenu, onToggle: onToggleMenu } = useModal();
  const navigations = [
    {
      name: 'Nhà bán',
      pathname: '/nha-dat-ban',
      extraProps: {},
      children: [
        {
          name: 'Bán nhà mặt phố',
          pathname: '/ban-nha-mat-pho',
          extraProps: {},
        },
        {
          name: 'Bán nhà riêng',
          pathname: '/ban-nha-rieng',
          extraProps: {},
        },
        {
          name: 'Bán biệt thự, nhà liền kề',
          pathname: '/ban-biet-thu-nha-lien-ke',
          extraProps: {},
        },
        {
          name: 'Bán cao ốc, văn phòng',
          pathname: '/ban-cao-oc-van-phong',
          extraProps: {},
        },
        {
          name: 'Bán khách sạn',
          pathname: '/ban-khach-san',
          extraProps: {},
        },
        {
          name: 'Bán nhà xưởng, kho, kiot',
          pathname: '/ban-nha-xuong-kho-kiot',
          extraProps: {},
        },
        {
          name: 'Bán đất nền',
          pathname: '/ban-dat-nen',
          extraProps: {},
        },
        {
          name: 'Bán căn hộ chung cư',
          pathname: '/ban-can-ho-chung-cu',
          extraProps: {},
        },
        {
          name: 'Bán shophouse',
          pathname: '/ban-shophouse',
          extraProps: {},
        },
        {
          name: 'Bán penthouse',
          pathname: '/ban-penthouse',
          extraProps: {},
        },
        {
          name: 'Bán condotel',
          pathname: '/ban-condotel',
          extraProps: {},
        },
        {
          name: 'Bán trang trại, khu nghỉ dưỡng',
          pathname: '/ban-trang-trai-khu-nghi-duong',
          extraProps: {},
        },
        {
          name: 'Bán nhà đất khác',
          pathname: '/ban-nha-dat-khac',
          extraProps: {},
        },
      ],
    },
    {
      name: 'Nhà thuê',
      pathname: '/nha-dat-cho-thue',
      extraProps: {},
      children: [
        {
          name: 'Cho thuê nhà mặt phố',
          pathname: '/cho-thue-nha-mat-pho',
          extraProps: {},
        },
        {
          name: 'Cho thuê nhà riêng',
          pathname: '/cho-thue-nha-rieng',
          extraProps: {},
        },
        {
          name: 'Cho thuê biệt thự, nhà liền kề',
          pathname: '/cho-thue-biet-thu-nha-lien-ke',
          extraProps: {},
        },
        {
          name: 'Cho thuê cao ốc, văn phòng',
          pathname: '/cho-thue-cao-oc-van-phong',
          extraProps: {},
        },
        {
          name: 'Cho thuê khách sạn',
          pathname: '/cho-thue-khach-san',
          extraProps: {},
        },
        {
          name: 'Cho thuê nhà xưởng, kho, kiot',
          pathname: '/cho-thue-nha-xuong-kho-kiot',
          extraProps: {},
        },
        {
          name: 'Cho thuê đất nền',
          pathname: '/cho-thue-dat-nen',
          extraProps: {},
        },
        {
          name: 'Cho thuê căn hộ chung cư',
          pathname: '/cho-thue-can-ho-chung-cu',
          extraProps: {},
        },
        {
          name: 'Cho thuê shophouse',
          pathname: '/cho-thue-shophouse',
          extraProps: {},
        },
        {
          name: 'Cho thuê penthouse',
          pathname: '/cho-thue-penthouse',
          extraProps: {},
        },
        {
          name: 'Cho thuê condotel',
          pathname: '/cho-thue-condotel',
          extraProps: {},
        },
        {
          name: 'Cho thuê phòng trọ',
          pathname: '/cho-thue-phong-tro',
          extraProps: {},
        },
        {
          name: 'Cho thuê nhà đất khác',
          pathname: '/cho-thue-nha-dat-khac',
          extraProps: {},
        },
      ],
    },
    {
      name: 'Dự án',
      pathname: '/du-an',
      extraProps: {},
    },
    {
      name: 'Bản đồ',
      pathname: '/ban-do',
      extraProps: {},
    },
    {
      name: 'Định giá',
      pathname: '/dinh-gia',
      extraProps: {},
    },
    {
      name: 'Tin tức',
      pathname: '/blog',
      extraProps: {},
      children: [
        {
          name: 'Kiến thức bất động sản',
          pathname: '/blog/kien-thuc-bat-dong-san',
          extraProps: {},
          children: [
            {
              name: 'Các loại bất động sản',
              pathname: '/blog/cac-loai-bat-dong-san',
              extraProps: {},
            },
            {
              name: 'So sánh các loại bất động sản',
              pathname: '/blog/so-sanh-cac-loai-bat-dong-san',
              extraProps: {},
            },
          ],
        },
        {
          name: 'Thông tin thị trường',
          pathname: '/blog/thong-tin-thi-truong',
          extraProps: {},
          children: [
            {
              name: 'Tin tức BĐS',
              pathname: '/blog/tin-tuc-bds',
              extraProps: {},
            },
            {
              name: 'Proptech Việt Nam',
              pathname: '/blog/proptech-viet-nam',
              extraProps: {},
            },
          ],
        },
        {
          name: 'Phong thủy nhà ở',
          pathname: '/blog/phong-thuy-nha-o',
          extraProps: {},
          children: [
            {
              name: 'Phong thủy phòng ngủ',
              pathname: '/blog/phong-thuy-phong-ngu',
              extraProps: {},
            },
            {
              name: 'Phong thủy phòng khách',
              pathname: '/blog/phong-thuy-phong-khach',
              extraProps: {},
            },
            {
              name: 'Phong thủy phòng tắm',
              pathname: '/blog/phong-thuy-phong-tam',
              extraProps: {},
            },
            {
              name: 'Phong thủy phòng bếp',
              pathname: '/blog/phong-thuy-phong-bep',
              extraProps: {},
            },
            {
              name: 'Phong thủy cửa chính, sân vườn',
              pathname: '/blog/phong-thuy-cua-chinh-san-vuon',
              extraProps: {},
            },
          ],
        },
        {
          name: 'Phong thủy nhà đất',
          pathname: '/blog/phong-thuy-nha-dat',
          extraProps: {},
          children: [
            {
              name: 'Phong thủy vị trí',
              pathname: '/blog/phong-thuy-vi-tri',
              extraProps: {},
            },
            {
              name: 'Phong thủy hình dạng đất',
              pathname: '/blog/phong-thuy-hinh-dang-dat',
              extraProps: {},
            },
          ],
        },
        {
          name: 'Tư vấn đầu tư bất động sản',
          pathname: '/blog/tu-van-dau-tu-bat-dong-san',
          extraProps: {},
          children: [
            {
              name: 'So sánh đầu tư BĐS và các kênh khác',
              pathname: '/blog/so-sanh-dau-tu-bds-va-cac-kenh-khac',
              extraProps: {},
            },
            {
              name: 'Vị trí bất động sản',
              pathname: '/blog/vi-tri-bat-dong-san',
              extraProps: {},
            },
            {
              name: 'Loại hình BĐS đầu tư',
              pathname: '/blog/loai-hinh-bds-dau-tu',
              extraProps: {},
            },
          ],
        },
        {
          name: 'Phong thủy theo tuổi',
          pathname: '/blog/phong-thuy-theo-tuoi',
          extraProps: {},
          children: [
            {
              name: 'Tý',
              pathname: '/blog/ty',
              extraProps: {},
            },
            {
              name: 'Sửu',
              pathname: '/blog/suu',
              extraProps: {},
            },
            {
              name: 'Dần',
              pathname: '/blog/dan',
              extraProps: {},
            },
            {
              name: 'Mão',
              pathname: '/blog/mao',
              extraProps: {},
            },
            {
              name: 'Thìn',
              pathname: '/blog/thin',
              extraProps: {},
            },
            {
              name: 'Tỵ',
              pathname: '/blog/ty-1',
              extraProps: {},
            },
            {
              name: 'Ngọ',
              pathname: '/blog/ngo',
              extraProps: {},
            },
            {
              name: 'Mùi',
              pathname: '/blog/mui',
              extraProps: {},
            },
            {
              name: 'Thân',
              pathname: '/blog/than',
              extraProps: {},
            },
            {
              name: 'Dậu',
              pathname: '/blog/dau',
              extraProps: {},
            },
            {
              name: 'Tuất',
              pathname: '/blog/tuat',
              extraProps: {},
            },
            {
              name: 'Hợi',
              pathname: '/blog/hoi',
              extraProps: {},
            },
          ],
        },
        {
          name: 'Vay vốn mua bất động sản',
          pathname: '/blog/vay-von-mua-bat-dong-san',
          extraProps: {},
        },
        {
          name: 'Thủ tục pháp lý bất động sản',
          pathname: '/blog/thu-tuc-phap-ly-bat-dong-san',
          extraProps: {},
          children: [
            {
              name: 'Kiểm tra quy hoạch',
              pathname: '/blog/kiem-tra-quy-hoach',
              extraProps: {},
            },
            {
              name: 'Lưu ý khi mua bán BĐS',
              pathname: '/blog/luu-y-khi-mua-ban-bds',
              extraProps: {},
            },
          ],
        },
      ],
    },
    {
      name: 'Mạng xã hội',
      pathname: SOCIAL_DOMAIN,
      extraProps: {
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    },
  ];

  const handleOnToggleModal = () => {
    onToggleMenu();
  };

  const renderMenuItems = (navigations: NavigationType[]) => {
    return (
      <ul className='divide-y pl-[12px]'>
        {map(navigations, (navigation, navigationIndex) =>
          isEmpty(navigation.children) ? (
            <li key={`menu-navigation-${navigationIndex}`}>
              <Link href={navigation.pathname ?? '#'}>
                <a
                  className='block py-[24px] text-[16px] leading-[20px]'
                  onClick={handleOnToggleModal}
                >
                  {navigation.name}
                </a>
              </Link>
            </li>
          ) : (
            <li key={`menu-navigation-${navigationIndex}`}>
              <Disclosure>
                {({ open }) => (
                  <Fragment>
                    <Disclosure.Button
                      className={`flex w-full items-center py-[24px] ${
                        open ? 'font-[600] text-primary' : 'text-text'
                      }`}
                    >
                      <span className='text-left text-[16px] leading-[20px]'>
                        {navigation?.name}
                      </span>
                      <HiChevronDown
                        className={`ml-[3px] min-h-[20px] min-w-[20px] ${
                          open ? 'rotate-180' : ''
                        } duration-[200ms]`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel>
                      {isEmpty(navigation.children) || isUndefined(navigation.children)
                        ? null
                        : renderMenuItems(navigation.children)}
                    </Disclosure.Panel>
                  </Fragment>
                )}
              </Disclosure>
            </li>
          ),
        )}
      </ul>
    );
  };

  return (
    <div id='menu' className='flex space-x-[20px]'>
      <button type='button' onClick={handleOnToggleModal}>
        <GiHamburgerMenu className='min-h-[24px] min-w-[24px]' />
      </button>
      <ModalWrapper
        isClickAway
        position='right'
        isShowing={isShowingMenu}
        onToggle={handleOnToggleModal}
      >
        <div
          className='flex h-[100vh] w-[276px] flex-col bg-paper'
          onClick={(event) => event.stopPropagation()}
        >
          <div className='flex justify-between px-[16px] py-[24px]'>
            <Link href='/'>
              <a>
                <div className='h-[32px] w-[28px] bg-[url("/favicon.svg")] bg-cover bg-center bg-no-repeat' />
              </a>
            </Link>
            <button className='p-[4px]' onClick={handleOnToggleModal}>
              <CgClose className='min-h-[24px] min-w-[24px] text-placeholder' />
            </button>
          </div>
          <div className='flex-1 overflow-y-auto pl-[12px] pr-[24px] pb-[200px]'>
            {renderMenuItems(navigations)}
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default Menu;
