import Link from 'next/link';

const HomeLink = () => (
  <Link id='home-link' href='/'>
    <a>
      <div className='h-[40px] w-[132px] bg-[url("/logos/atm-nha-colored.svg")] bg-cover bg-center bg-no-repeat' />
    </a>
  </Link>
);

export default HomeLink;
