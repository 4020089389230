import { isEqual } from 'lodash';
import { useScroll } from 'hooks';
import Back from './back';
import HomeLink from './home-link';
import Menu from './menu';

const Header2 = () => {
  const { direction } = useScroll();
  const isScrollDown = isEqual(direction, 'down');

  return (
    <header
      id='header2'
      className={`sticky left-0 right-0 z-[50] flex h-[48px] items-center justify-between space-x-[16px] bg-paper px-[16px] py-[4px] transition-all duration-[200ms] ease-in-out ${
        isScrollDown ? 'top-[-48px]' : 'top-0'
      }`}
    >
      <Back />
      <HomeLink />
      <Menu />
    </header>
  );
};

export default Header2;
